import { EuiPageTemplate } from '@elastic/eui'

const ErrorMessageComponent = ({ message }: { message?: string }) => {
  return (
    <EuiPageTemplate.Section>
      {message || 'Something went wrong !! Please try after some time.'}
    </EuiPageTemplate.Section>
  )
}

export default ErrorMessageComponent
