/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */

import React, { AnchorHTMLAttributes } from 'react'
import Link from 'next/link'
import { classNames } from '@sergeimeza/uikit-next'

export type AnchorProps = {
  className?: string
  to?: string
  href?: string
}

const AnchorComponent: React.FC<
  AnchorProps & AnchorHTMLAttributes<HTMLAnchorElement>
> = ({
  className = '',
  to = undefined,
  href = undefined,
  children,
  id,
  ...props
}) => (
  <Link href={to ?? href ?? ''} {...props}>
    <a id={id} className={classNames(className)}>
      {children}
    </a>
  </Link>
)

export const Anchor = Object.assign(AnchorComponent, {})

export default Anchor
