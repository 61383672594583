import {
  NovuProvider,
  PopoverNotificationCenter,
  NotificationBell,
} from '@novu/notification-center'

/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { css } from '@emotion/react'

import { useKeyPress } from 'ahooks'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMessage } from '@fortawesome/free-solid-svg-icons'

import {
  EuiHeader,
  EuiHeaderSectionItemButton,
  useGeneratedHtmlId,
  EuiIcon,
  EuiHeaderSection,
  EuiHeaderSectionItem,
  EuiPopover,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiSpacer,
  EuiLink,
  EuiButton,
  EuiCollapsibleNav,
  EuiSelectableTemplateSitewide,
  useEuiTheme,
  EuiSelectableTemplateSitewideOption,
  EuiBadge,
  EuiLoadingSpinner,
} from '@elastic/eui'

import type { EuiBreadcrumbProps } from '@elastic/eui/src/components/breadcrumbs/breadcrumb'

import { useTranslationContext } from '../../context/TranslationContext'
import { useAuthContext } from '../../context/AuthContext'

import LemonImage from '../Images/LemonImage'

import { Prisma } from '../../interfaces'

import LemonLogo from '../../../assets/img/brand/logo-lemonsquare-horizontal.svg'
import { Sidebar } from '../Sidebar/sidebar'

const operatorRoles: string[] = [
  Prisma.UserRole.ADMIN,
  Prisma.UserRole.OPERATOR,
  Prisma.UserRole.SALES,
]

/**
 * The options object
 */
const searchData: EuiSelectableTemplateSitewideOption[] = [
  {
    label: 'ダッシュボード > 概要',
    meta: [
      {
        text: 'dashboard',
        type: 'application',
        highlightSearchString: true,
      },
    ],
    url: '/app/dashboard',
  },
  {
    label: 'ダッシュボード > Instagram',
    meta: [
      {
        text: 'dashboard',
        type: 'application',
        highlightSearchString: true,
      },
    ],
    url: '/app/dashboard/instagram',
  },
  {
    label: 'ダッシュボード > TikTok',
    meta: [
      {
        text: 'dashboard',
        type: 'application',
        highlightSearchString: true,
      },
    ],
    url: '/app/dashboard/tiktok',
  },
  {
    label: 'インフルエンサー',
    meta: [
      {
        text: 'influencer',
        type: 'application',
        highlightSearchString: true,
      },
    ],
    url: '/app/influencer',
  },
  {
    label: 'ブランド',
    meta: [
      {
        text: 'brand',
        type: 'application',
        highlightSearchString: true,
      },
    ],
    url: '/app/brand',
  },
  {
    label: '商品管理',
    meta: [
      {
        text: 'product',
        type: 'application',
        highlightSearchString: true,
      },
    ],
    url: '/app/product',
  },
  {
    label: 'キャンペーン',
    meta: [
      {
        text: 'campaign',
        type: 'application',
        highlightSearchString: true,
      },
    ],
    url: '/app/campaign',
  },
  {
    label: 'ファンマーケティング',
    meta: [
      {
        text: 'fan-marketing',
        type: 'application',
        highlightSearchString: true,
      },
    ],
    url: '/app/fan-marketing',
  },
  {
    label: 'クライアント管理',
    meta: [
      {
        text: 'client',
        type: 'application',
        highlightSearchString: true,
      },
    ],
    url: '/app/admin/client',
  },
  {
    label: 'ユーザー登録',
    meta: [
      {
        text: 'user',
        type: 'application',
        highlightSearchString: true,
      },
    ],
    url: '/app/admin/user',
  },
  {
    label: '発送管理 > 在庫管理',
    meta: [
      {
        text: 'warehouse',
        type: 'application',
        highlightSearchString: true,
      },
    ],
    url: '/app/warehouse/stock',
  },
  {
    label: '発送管理 > 検品管理',
    meta: [
      {
        text: 'warehouse',
        type: 'application',
        highlightSearchString: true,
      },
    ],
    url: '/app/warehouse/inspection',
  },
  {
    label: '発送管理 > 発送管理',
    meta: [
      {
        text: 'warehouse',
        type: 'application',
        highlightSearchString: true,
      },
    ],
    url: '/app/warehouse/delivery',
  },
  {
    label: 'アプリ内お知らせ管理',
    meta: [
      {
        text: 'notification',
        type: 'application',
        highlightSearchString: true,
      },
    ],
    url: '/app/notification/app-notification',
  },
]

const HeaderSitewideSearch = () => {
  const isMac = navigator.platform.toLowerCase().indexOf('mac') >= 0

  const blurEvent = new FocusEvent('focusout', {
    bubbles: true,
  })

  const router = useRouter()

  const [searchValue, setSearchValue] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [searchRef, setSearchRef] = useState<HTMLInputElement | null>(null)

  const searchValueExists = searchValue?.length

  /**
   * Timeout to simulate loading (only on key command+k)
   */
  let searchTimeout
  const startSearchTimeout = () => {
    searchTimeout = setTimeout(() => {
      // Simulate a remotely-executed search.
      setLoading(false)
    }, 400)
  }
  clearTimeout(searchTimeout)
  startSearchTimeout()

  /**
   * Hook up the keyboard shortcut for command+k to initiate focus into search input
   */
  useEffect(() => {
    const onWindowKeyUp = () => {
      searchRef?.focus()
      setLoading(true)
      window.removeEventListener('keyup', onWindowKeyUp)
    }

    const onWindowKeyDown = (e: any) => {
      if ((isMac ? e.metaKey : e.ctrlKey) && e.key.toLowerCase() === 'k') {
        e.preventDefault()
        window.addEventListener('keyup', onWindowKeyUp)
      }
    }

    window.addEventListener('keydown', onWindowKeyDown)

    return () => {
      window.removeEventListener('keydown', onWindowKeyDown)
      window.removeEventListener('keyup', onWindowKeyUp)
    }
  }, [searchRef])

  const onKeyUpCapture = (e: any) => {
    setSearchValue(e.currentTarget.value)
  }

  /**
   * Do something with the selection based on the found option with `checked: on`
   */
  const onChange = (updatedOptions: EuiSelectableTemplateSitewideOption[]) => {
    const clickedItem = updatedOptions.find(option => option.checked === 'on')
    if (!clickedItem) return

    router.push(clickedItem.url)
  }

  const { euiTheme } = useEuiTheme()

  return (
    <EuiSelectableTemplateSitewide
      className='kbnSearchBar'
      isLoading={isLoading}
      onChange={onChange}
      options={searchValueExists ? searchData : searchData}
      searchProps={{
        append: isMac ? '⌘K' : '^K',
        compressed: true,
        onKeyUpCapture,
        className: 'customSearchClass',
        inputRef: setSearchRef,
        fullWidth: true,
      }}
      listProps={{
        className: 'customListClass',
        css: css`
          .euiSelectableTemplateSitewide__optionMeta--PINK {
            font-weight: ${euiTheme.font.weight.medium};
            color: ${euiTheme.colors.accentText};
          }
        `,
      }}
      popoverProps={{
        className: 'customPopoverClass',
      }}
      popoverButton={<EuiButton>Mobile toggle</EuiButton>}
      popoverButtonBreakpoints={['xs', 's']}
      singleSelection
      popoverFooter={
        <EuiText color='subdued' size='xs'>
          <EuiFlexGroup
            alignItems='center'
            gutterSize='s'
            responsive={false}
            wrap
          >
            <EuiFlexItem grow={false}>
              {searchValueExists && <EuiLink>View more results</EuiLink>}
            </EuiFlexItem>
            <EuiFlexItem />
            <EuiFlexItem grow={false}>Quickly search using</EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiBadge>{isMac ? 'Command + K' : 'Control + K'}</EuiBadge>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiText>
      }
    />
  )
}

const HeaderChat = () => {
  const { translation } = useTranslationContext()
  const { appUser } = useAuthContext()

  const router = useRouter()

  if (!appUser) return null

  const chatRoles: Prisma.UserRole[] = [
    Prisma.UserRole.ADMIN,
    Prisma.UserRole.OPERATOR,
    Prisma.UserRole.SALES,
    Prisma.UserRole.CLIENT_MEMBER,
    Prisma.UserRole.CLIENT_OWNER,
  ]

  if (!chatRoles.includes(appUser.role)) return null

  const onMenuButtonClick = () => {
    router.push('/app/chat')
  }

  return (
    <EuiHeaderSectionItem>
      <EuiButton
        className='mx-2'
        size='s'
        aria-label='Chat menu'
        onClick={onMenuButtonClick}
        color='accent'
      >
        <div className='my-2 flex h-8 flex-row items-center justify-center space-x-2 rounded-sm px-2 pt-[2px] text-black'>
          <FontAwesomeIcon icon={faMessage} />
          <p className='shrink-0 font-medium'>{translation.CHAT}</p>
        </div>
      </EuiButton>
    </EuiHeaderSectionItem>
  )
}

const HeaderNotifications = () => {
  const { appUser } = useAuthContext()

  if (!process.env.NEXT_PUBLIC_NOVU_APP_ID) {
    return (
      <EuiLoadingSpinner
        color={{ highlight: '#fff', border: '#aaa' }}
        size='l'
      />
    )
  }

  // const bellButton = (
  //   <EuiHeaderSectionItemButton
  //   >
  //     <EuiIcon type='bell' />
  //   </EuiHeaderSectionItemButton>
  // )

  return (
    <NovuProvider
      stores={[{ storeId: 'cms_news', query: { feedIdentifier: 'cms_news' } }]}
      backendUrl={process.env.NEXT_PUBLIC_NOVU_BACKEND_URL}
      socketUrl={process.env.NEXT_PUBLIC_NOVU_SOCKET_URL}
      subscriberId={appUser ? 'cms-' + appUser.id.toString() : undefined}
      applicationIdentifier={process.env.NEXT_PUBLIC_NOVU_APP_ID}
    >
      <PopoverNotificationCenter colorScheme='light'>
        {({ unseenCount }) => <NotificationBell unseenCount={unseenCount} />}
      </PopoverNotificationCenter>
    </NovuProvider>
  )
}

const HeaderUserMenu = () => {
  const userPopoverId = useGeneratedHtmlId({ prefix: 'userPopover' })
  const [isOpen, setIsOpen] = useState(false)

  const { translation } = useTranslationContext()
  const { appUser, logOut } = useAuthContext()

  if (!appUser) return null

  const userName = appUser ? `${appUser.lastName} ${appUser.firstName}` : 'User'

  const myCompanyRoles: Prisma.UserRole[] = [
    Prisma.UserRole.CLIENT_OWNER,
    Prisma.UserRole.CLIENT_MEMBER,
    Prisma.UserRole.BRAND_AUDITOR,
  ]

  const memberManagementRoles: Prisma.UserRole[] = [
    Prisma.UserRole.CLIENT_OWNER,
    Prisma.UserRole.CLIENT_MEMBER,
  ]

  const onMenuButtonClick = () => {
    setIsOpen(!isOpen)
  }

  const closeMenu = () => {
    setIsOpen(false)
  }

  const button = (
    <EuiHeaderSectionItemButton
      id='user-menu'
      aria-controls={userPopoverId}
      aria-expanded={isOpen}
      aria-haspopup='true'
      aria-label='Account menu'
      onClick={onMenuButtonClick}
    >
      <EuiIcon type='apps' size='m' />
    </EuiHeaderSectionItemButton>
  )

  return (
    <EuiPopover
      id={userPopoverId}
      repositionOnScroll
      button={button}
      isOpen={isOpen}
      anchorPosition='downRight'
      closePopover={closeMenu}
      panelPaddingSize='none'
    >
      <div style={{ width: 320 }}>
        <EuiFlexGroup
          gutterSize='m'
          className='euiHeaderProfile'
          responsive={false}
        >
          <EuiFlexItem>
            <EuiText>{translation['Welcome!']}</EuiText>

            <EuiFlexItem grow={false}>{userName}</EuiFlexItem>

            <EuiFlexItem grow={false}>
              <p id='account-role' className='text-overflow m-0'>
                {appUser?.role}
              </p>
            </EuiFlexItem>

            <EuiSpacer size='m' />

            <EuiFlexItem grow={false}>
              {myCompanyRoles.includes(appUser.role) && (
                <EuiLink className='mb-2'>
                  <Link href='/app/account/my-company'>
                    <div className='flex items-center space-x-2'>
                      <i className='ni ni-building' />
                      <span>{translation.COMPANY_INFORMATION}</span>
                    </div>
                  </Link>
                </EuiLink>
              )}
              {memberManagementRoles.includes(appUser.role) && (
                <EuiLink className='mb-2'>
                  <Link href='/app/account/own-member'>
                    <div className='flex items-center space-x-2'>
                      <i className='ni ni-settings-gear-65' />
                      <span>{translation['Member management']}</span>
                    </div>
                  </Link>
                </EuiLink>
              )}
            </EuiFlexItem>

            <EuiSpacer size='m' />

            <EuiFlexItem grow={false}>
              <EuiButton onClick={() => logOut()}>
                {translation.Logout}
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    </EuiPopover>
  )
}

interface IDashboardHeaderProps {
  // eslint-disable-next-line react/require-default-props
  breadcrumbs?: EuiBreadcrumbProps[]
}

const DashboardHeader: FC<IDashboardHeaderProps> = ({ breadcrumbs }) => {
  const [navIsOpen, setNavIsOpen] = useState(false)

  const router = useRouter()
  const { appUser } = useAuthContext()

  const userRole = appUser?.role ?? ''

  useKeyPress(
    ['ctrl.shift.1'],
    () => {
      if (operatorRoles.includes(userRole)) {
        router.push('/app/search/influencer')
      }
    },
    { exactMatch: true },
  )

  useKeyPress(
    ['ctrl.shift.2'],
    () => {
      if (operatorRoles.includes(userRole)) {
        router.push('/app/search/instagram-post')
      }
    },
    { exactMatch: true },
  )

  useKeyPress(
    ['ctrl.shift.3'],
    () => {
      if (operatorRoles.includes(userRole)) {
        router.push('/app/brand')
      }
    },
    { exactMatch: true },
  )
  useKeyPress(
    ['ctrl.shift.4'],
    () => {
      if (operatorRoles.includes(userRole)) {
        router.push('/app/product')
      }
    },
    { exactMatch: true },
  )
  useKeyPress(
    ['ctrl.shift.5'],
    () => {
      if (operatorRoles.includes(userRole)) {
        router.push('/app/orientation')
      }
    },
    { exactMatch: true },
  )
  useKeyPress(
    ['ctrl.shift.6'],
    () => {
      if (operatorRoles.includes(userRole)) {
        router.push('/app/campaign')
      }
    },
    { exactMatch: true },
  )
  useKeyPress(
    ['ctrl.shift.7'],
    () => {
      if (operatorRoles.includes(userRole)) {
        router.push('/app/warehouse/stock')
      }
    },
    { exactMatch: true },
  )
  useKeyPress(
    ['ctrl.shift.8'],
    () => {
      if (operatorRoles.includes(userRole)) {
        router.push('/app/warehouse/inspection')
      }
    },
    { exactMatch: true },
  )
  useKeyPress(
    ['ctrl.shift.9'],
    () => {
      if (operatorRoles.includes(userRole)) {
        router.push('/app/warehouse/delivery')
      }
    },
    { exactMatch: true },
  )
  useKeyPress(
    ['ctrl.shift.0'],
    () => {
      if (operatorRoles.includes(userRole)) {
        router.push('/app/notification/app-notification')
      }
    },
    { exactMatch: true },
  )

  const collapsibleNavId = useGeneratedHtmlId({ prefix: 'collapsibleNav' })

  // eslint-disable-next-line no-underscore-dangle
  const _breadcrumbs: EuiBreadcrumbProps[] = breadcrumbs?.length
    ? breadcrumbs
    : [
        {
          text: 'Home',
          onClick: () => {
            router.replace('/app/dashboard')
          },
        },
      ]

  const collapsibleNav = (
    <EuiCollapsibleNav
      ownFocus={false}
      css={css`
        display: flex;
      `}
      id={collapsibleNavId}
      aria-label='Main navigation'
      isOpen={navIsOpen}
      button={
        <EuiHeaderSectionItemButton
          id='collapsible-nav-button'
          aria-label='Toggle main navigation'
          onClick={() => setNavIsOpen(!navIsOpen)}
        >
          <EuiIcon type='menu' size='m' aria-hidden='true' />
        </EuiHeaderSectionItemButton>
      }
      onClose={() => setNavIsOpen(false)}
    >
      <Sidebar />
    </EuiCollapsibleNav>
  )

  const leftSectionItems = [collapsibleNav]

  const globalSearchRoles: Prisma.UserRole[] = [
    Prisma.UserRole.ADMIN,
    Prisma.UserRole.OPERATOR,
    Prisma.UserRole.SALES,
  ]

  if (!appUser) return null

  return (
    <>
      <EuiHeader theme='dark' position='fixed'>
        <EuiHeaderSection grow={false}>
          <div className='mt-[8px] h-[32px] rounded bg-white px-2'>
            <div className='relative mb-4 mt-[1px] h-[30px] w-[180px]'>
              <LemonImage
                layout='fill'
                height={32}
                width={180}
                objectFit='contain'
                alt='Lemon Square'
                src={LemonLogo}
              />
            </div>
          </div>
        </EuiHeaderSection>
        {/* Global Search Bar */}
        {globalSearchRoles.includes(appUser.role) && (
          <EuiHeaderSection grow>
            <div className='flex w-full flex-row items-center justify-center'>
              <EuiHeaderSectionItem>
                <HeaderSitewideSearch />
              </EuiHeaderSectionItem>
            </div>
          </EuiHeaderSection>
        )}

        <EuiHeaderSection side='right' grow={false}>
          <EuiHeaderSectionItem>
            <HeaderChat />
          </EuiHeaderSectionItem>
          <EuiHeaderSectionItem>
            <HeaderNotifications />
          </EuiHeaderSectionItem>
          <EuiHeaderSectionItem>
            <HeaderUserMenu />
          </EuiHeaderSectionItem>
        </EuiHeaderSection>
      </EuiHeader>

      <EuiHeader
        position='fixed'
        sections={[
          {
            items: leftSectionItems,
          },
          {
            items: [],
            breadcrumbs: _breadcrumbs,
          },
        ]}
      />
    </>
  )
}

export { DashboardHeader }
