import React, { useState } from 'react'
import { useRouter } from 'next/router'
import { Disclosure } from '@headlessui/react'
import { classNames } from '@sergeimeza/uikit-next'

import { NavigationItem } from './NavigationItem'

import { Anchor } from './Anchor'

export type VerticalNavigationProps = {
  items?: NavigationItem[]
  secondaryNavigationTitle?: string
  secondaryItems?: NavigationItem[]
  showIcons?: boolean
  onItemSelected?: (item: NavigationItem) => void
}

export const VerticalNavigation: React.FC<VerticalNavigationProps> = ({
  items = [],
  secondaryNavigationTitle = undefined,
  secondaryItems = undefined,
  showIcons = true,
}) => {
  const router = useRouter()

  // memo or state

  const [navItems] = useState<NavigationItem[]>(items.slice())

  return (
    <nav aria-label='Sidebar'>
      <div className='mr-4 space-y-1'>
        {navItems.map(item => {
          const current = router.pathname.includes(item.to)
          return !item.children ? (
            <Anchor
              id={item.id}
              to={item.to}
              key={item.name}
              aria-current={current ? 'page' : undefined}
              className={classNames(
                current
                  ? 'text-primary-900'
                  : 'text-gray-600 hover:bg-gray-100 hover:text-gray-900',
                'focus:ring-primary-500 focus:border-primary-500 group flex w-full items-center rounded-md px-3 py-2 text-sm font-medium focus:outline-none',
              )}
            >
              {showIcons && (
                <i
                  className={classNames(item.icon, current && item.iconColor)}
                />
              )}
              <span className='truncate'>{item.name}</span>
              {item.count ? (
                <span
                  className={classNames(
                    current
                      ? 'bg-white'
                      : 'bg-gray-100 group-hover:bg-gray-200',
                    'ml-auto inline-block rounded-full py-0.5 px-3 text-xs',
                  )}
                >
                  {item.count}
                </span>
              ) : null}
            </Anchor>
          ) : (
            <Disclosure
              as='div'
              id={item.id}
              key={item.name}
              defaultOpen={current}
              className='space-y-1'
            >
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={classNames(
                      current
                        ? 'text-gray-900'
                        : 'text-gray-600 hover:text-gray-900',
                      'focus:ring-primary-500 group flex w-full items-center rounded-md py-2 pl-[1rem] pr-2 text-left text-sm font-medium focus:outline-none',
                      open ? 'bg-gray-50' : 'bg-white',
                    )}
                  >
                    {showIcons && (
                      <i
                        className={classNames(
                          item.icon,
                          open && item.iconColor,
                        )}
                      />
                    )}
                    {item.name}
                    <span className='block grow'>
                      <svg
                        className={classNames(
                          open ? 'rotate-90 text-gray-400' : 'text-gray-300',
                          'ml-auto mr-2 h-5 w-5 shrink-0 transition-colors duration-150 ease-in-out group-hover:text-gray-400',
                        )}
                        viewBox='0 0 20 20'
                        aria-hidden='true'
                      >
                        <path d='M6 6L14 10L6 14V6Z' fill='currentColor' />
                      </svg>
                    </span>
                  </Disclosure.Button>
                  <Disclosure.Panel className='space-y-1'>
                    {item.children?.map(subItem => (
                      <Anchor
                        id={subItem.id}
                        key={subItem.name}
                        to={subItem.to}
                        className={classNames(
                          subItem.to === router.pathname
                            ? 'text-primary-900 rounded-none border-l-2 border-[#5E71E4]'
                            : 'text-gray-600 hover:bg-gray-100 hover:text-gray-900',
                          'group ml-2 mr-8 flex w-full items-center rounded-md py-2 pl-10 pr-2 text-sm font-medium focus:outline-none',
                        )}
                      >
                        {subItem.name}
                      </Anchor>
                    ))}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          )
        })}
      </div>
      {secondaryItems && secondaryItems.length > 0 && (
        <div className='mt-8'>
          {secondaryNavigationTitle && (
            <h3
              className='px-3 text-xs font-semibold uppercase tracking-wider text-gray-500'
              id='projects-headline'
            >
              {secondaryNavigationTitle}
            </h3>
          )}
          <div className='mt-1 space-y-1' aria-labelledby='projects-headline'>
            {secondaryItems?.map(item => (
              <Anchor
                id={item.id}
                key={item.name}
                to={item.to}
                className={classNames(
                  'group flex w-full items-center rounded-md px-3 py-2 text-sm font-medium text-gray-600',
                  'hover:bg-gray-100 hover:text-gray-900',
                  'focus:ring-primary-500 focus:border-primary-500 focus:outline-none',
                )}
              >
                <span className='truncate'>{item.name}</span>
              </Anchor>
            ))}
          </div>
        </div>
      )}
    </nav>
  )
}

export default VerticalNavigation
