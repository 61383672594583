import { useRouter } from 'next/router'
import { trpc } from '../services/trpc'
import { errorHandler } from 'common'

const ErrorReportingFallbackComponent = ({
  error,
  children,
  componentName,
}) => {
  const errorPath = useRouter()
  const context = trpc.useContext()
  errorHandler(error, errorPath, componentName, context)

  return <div>{children}</div>
}

export { ErrorReportingFallbackComponent }
